@import url("https://fonts.googleapis.com/css2?family=Marvel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Klee+One&display=swap");
@import url(http://fonts.googleapis.com/earlyaccess/khyay.css);
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100&display=swap');

::-webkit-scrollbar {
  width: 0.3vw; /* Width of the scrollbar */
  background-color: rgba(
    200,
    182,
    214,
    0.33
  ); /* Background color of the scrollbar track */
  /* display:none; */
  border: none;
  padding: none;
  margin: none;
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #72658fb6; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the scrollbar thumb */
}

/* Customize the scrollbar track when the mouse hovers over it */
::-webkit-scrollbar-thumb:hover {
  background-color: #403e68;
}

/* Customize the scrollbar track when it's in a "pressed" state (clicking and holding the thumb) */
::-webkit-scrollbar-thumb:active {
  background-color: #1f1e33;
}
.loading-data{
  font-size: 1.5rem;
  font-family: Marvel;
  padding: 10px 10px;
  width: 100%;
}

body {
  margin: 0;
  text-decoration: none;
  font-size: 0.8rem;
  padding: none;
  border: none;
}

body ::selection {
  background-color: rgba(181, 158, 199, 0.568);
}

header {
  height: 500px;
  letter-spacing: 3px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.712),
    rgba(255, 255, 255, 0)
  );
}

header .container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
}

header .container1 {
  padding: 5px;
  font-size: 18px;
}

header .container2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 5px;
  width: 100%;
}

header a {
  font-family: "Marvel";
  text-decoration: none;
  font-weight: 100;
  font-style: normal;
  color: black;
}

#jp {
  font-family: "Klee One", cursive;
  letter-spacing: 0px;
  padding: 5px 20px 0px 20px;
}
#jp2 {
  font-family: "Klee One", cursive;
  letter-spacing: 0px;
  padding: 0px;
  font-size: 19px;
  color: #1f1e33;
  background-color: #1f1e33;
}
#jp2:hover{
  background-color: #00000000;
  transition: background-color 0.5s ease-in-out;
}

.quote {
  max-width: 1280px;
  height: 70px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.quote p {
  font-size: 1rem;
}

.p1 {
  font-size: 1.4rem;
  padding: 30px;
  padding-left: 15px;
  margin: 0;
  font-family: "marvel";
  font-size: 14px;
  z-index: 9;
  position: absolute;
  left: 0;
}
.p1 .heading {
  text-decoration: none;
  color: #1f1e33;
}

.p2 {
  font-size: 20px;
  padding: 30px;
  width: 100%;
  margin: 0;
  text-align: center;
  font-family: "marvel";
  font-size: 14px;

  position: absolute;
  /* top: 0; */
  /* left: 0; */
}

.pages {
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  margin: 0 auto;
  /* padding: 5px 15px; */
  /* background-color: rgb(96, 124, 124); */
  justify-content: space-between;
}

.home {
  width: 70%;
  padding: 15px 15px;
  /* height: 200vh; */
}

.about {
  height: 100vh;
  width: 100%;
}

.sidebar {
  width: 25%;
  padding: 15px 15px;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
}

/* Namecard */
.namecard {
  /* background-color: #1f1e33; */
  height: 240px;
  background: rgba(200, 182, 214, 0.33);
  box-shadow: 0px 4px 10px 2px rgba(146, 143, 198, 0.5);
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 2;
}
.namecardsecondlayer {
  height: 100px;
  background-color: #1f1e33;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
}
.namecardthirdlayer {
  height: 130px;
  width: 93.75%;
  background-color: #fff;
  margin: 0px 0px 10px 10px;
  z-index: 0;
}
.namecard-quote {
  padding: 40px;
  padding-top: 62px;
  font-family: Marvel;
  font-size: 18px;
  justify-content: center;
  display: flex;
  text-align: center;
  color: #1f1e33;
}


.image {
  width: 87px;
  height: 87px;
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  border-radius: 50%;
}

.containeri {
  position: relative;
  width: 100%;
  height: 200px;
}

.content {
  position: relative;
  width: 320px;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

/* Post  */
.post,
.about-me {
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(2, 0, 114, 0.5);
  height: 230px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.about-me{
  width: 98.4%;
  height: 200vh;
}
.post .titlebar {
  display: flex;
  flex-direction: row;
  /* background-color: #a4dd7586; */
  padding: 30px;
  padding-bottom: 0px;
}
.post .tagline {
  width: 1%;
  background: #928fc6;
  height: 30px;
  min-width: 3px;
}
.post .tag {
  width: 13%;
  background: rgba(172, 168, 245, 0.692);
  height: 30px;
  min-width: 50px;
}
.post .tag button {
  font-size: 1.05rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "Khyay", sans-serif;
  font-weight: lighter;
  color: #fff;
  padding-top: 6px;
  background-color: #00000000;
  border: none;
  width: 100%;
  transition: background-color 0.5s ease-in-out;
}

.post .tag button:hover {
  background-color: #2c2b49;
  transition: background-color 0.5s ease-in-out;
}

.post .title {
  text-decoration: none;
  font-size: 1.6rem;
  /* width: 80%; */ 
  /* recently modified */
  padding-left: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 30px;
  color: #683daf;
  font-family: Marvel;
  border: none;
  background-color: #00000000;
  transition: color 0.5s;
}
.post .title:hover {
  color: #cfc2e5;
  transition: color 0.5s;
}

.post .title2 {
  text-decoration: none;
  font-size: 1.6rem;
  /* width: 80%; */ 
  /* recently modified */
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #683daf;
  font-family: Marvel;
  border: none;
  background-color: #00000000;
  transition: color 0.5s;
}
.post .title2:hover {
  color: #cfc2e5;
  transition: color 0.5s;
}

.post .body {
  padding: 30px;
  padding-top: 20px;
  padding-right: 60px;
  padding-bottom: 0px;
  font-size: 1.2rem;
  font-family: Marvel;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.808);
}
.post .data,
.comment .data {
  /* position: relative; */
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.post .data h2,
.comment .data h2 {
  padding-left: 8px;
  font-family: Marvel;
  color: rgba(0, 0, 0, 0.31);
  font-size: 1.2rem;
  width: auto;
  margin: 0;
  padding-right: 30px;
}

/* Single Post */
.singlepost-container {
  width: 70%;
}
.single-post,
.comment {
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(2, 0, 114, 0.5);
  /* min-height: 1000px; */
  margin-bottom: 50px;
  padding: 0px;
  margin: 0;
  margin-top: 15px;
  margin-left: 15px;
}
.single-post .title {
  color: #683daf;
  text-align: center;
  font-family: Marvel;
  font-size: 2.5rem;
  padding: 50px;
  padding-bottom: 20px;
}
.single-post .published-date,
.last-updated {
  color: rgba(0, 0, 0, 0.31);
  font-family: Marvel;
  font-size: 1.5rem;
  text-align: center;
}
.single-post .body {
  color: rgba(0, 0, 0, 0.69);
  font-family: Marvel;
  font-size: 1.4rem;
  padding: 65px;
}
.single-post .line-breaking,
.comment .line-breaking {
  content: "";
  width: 90%;
  height: 0.15rem;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.single-post .post-footer {
  padding: 45px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.post-footer .tag {
  color: #fff;
  text-align: center;
  font-family: "Khyay", sans-serif;
  font-size: 1.0rem;
  background: #928fc6b9;
  width: 6rem;
  height: 2rem;
}
.post-footer .tag .tag-input {
  padding-top: 5.5px;
}
.post-footer .last-updated {
  font-size: 1.3rem;
  color: #1f1e33a1;
}
.singlepost-container .comment {
  margin-top: 50px;
  margin-bottom: 100px;
}
.comment .data {
  padding: 40px 45px 10px 45px;
}
.comment-input{
 padding: 30px 40px 50px 40px; 
 font-family: Marvel;
 font-size: 1.2rem;
}
.comment-input .title{
  color: #1f1e33;
  padding: 10px;
  background: rgba(146, 143, 198, 0.24);
  font-weight: 500;
}
.comment-input .inputbox{
  display: flex;
  flex-direction: row;
}
.comment-input textarea{
  width: 98%;
  border: none;
  height: 100px;
  /* box-sizing: border-box; */
  font-family: Marvel;
  padding-left: 5px;
  font-size: 1.2rem;
  resize: none
}
.comment-input textarea:focus {
  /* border: 2px solid #928fc68c; */
  outline-width: 2px;
  outline-offset: -5;
  outline-style: solid;
  outline-color:#928fc68c;
}
.comment-input textarea::placeholder {
  font-family: Marvel;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.342);
}
.comment-input textarea::selection {
  background-color: rgba(200, 182, 214, 0.568);
  color: #000;
}
.comment-input .fill{
  background: rgba(146, 143, 198, 0.24);
  width: 1%;
}
.comment-input .submit{
  background: rgba(146, 143, 198, 0.24);
  height: 50px;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding-right: 1%;
}
.comment-input button{
  margin-left: auto;
  height: 30px;
  border: none;
  background: rgba(37, 34, 88, 0.573);
  font-family: Marvel;
  font-size: 1.3rem;
  width: 15%;
  color: #f9f9ff;
  transition: background 0.2s ease-in-out;
}
.comment-input button:hover{
  background: #9897c1be;
  transition: background 0.2s ease-in-out;
}
.comment-input .info{
  display: flex;
  padding-left: 1%;
  width: 53%;
  justify-content: space-between;
}
.comment-input input{
  border: none;
  color:rgba(0, 0, 0, 0.466)

}
.comment-input input:focus{
  outline-width: 2px;
  outline-offset: unset;
  outline-style: solid;
  outline-color:#928fc68c;
}

.single-comment-wrapper{
  display: flex;
  flex-direction: column;
}
.single-comment-wrapper .line-breaking{
  height: 0.1rem;
}
.single-comment {
  display: flex;
  padding: 40px 45px 40px 45px;
  font-family: Marvel;
}
.single-comment-wrapper button{
  padding: 0;
  background: none;
  border: none;
  display: flex;
  justify-content: baseline;
  height:1.4rem;
  margin-top: auto; 
}
.single-comment-wrapper button:hover{
    /* src: url("images/garbage_can5.png"); */
    /* transform: scale(1.1); Optional: Add a zoom effect */
}
#myImage:hover {
  cursor: pointer; /* Optional: Change cursor to pointer on hover */
}
.single-comment img{
  height:1.4rem;
}
.single-comment .comment-body {
  display: flex;
  align-items: center;
  padding: 0px 30px 0px 30px;
  font-size: 1.2rem;
  color: hsla(0, 0%, 0%, 0.677);
  max-width: 75%;
}
.single-comment .comment-header {
  padding: 0px 0px 0px 30px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.comment-header .username {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  margin: 10px;
}
.comment-header .commented-date {
  color: rgba(0, 0, 0, 0.31);
  font-size: 1.2rem;
  margin: 0;
}
/* .about-me{
  width: 85%;
  padding: 50px;
  
} */

/* dropdown  */
.menu {
  position: absolute;
  list-style-type: none;
  margin: 0 0;
  padding: 0;
  width: 130px;
  overflow: hidden;
  height: 0;
  background-color: rgba(255, 255, 255, 0.534);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  
  /* transform: translateX(-7px); */
}

.menu.open {
  height: auto;
  padding: 0 0;
  transform: translateX(-7px);
}

.menu.open2 {
  height: auto;
  padding: 0 0;
  transform: translateX(-7px);
}

.menu button {
  width: 100%;
  text-align: center;
  background: none;
  color: inherit;
  border: none;
  padding: 15px;
  font: inherit;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Marvel";
  font-size: 23px;
  font-weight: 300;
}

.menu button:hover {
  background-color: rgba(26, 16, 48, 0.062);
}

/* Search Bar  */
.searchbar {
  background: rgba(200, 182, 214, 0.33);
  box-shadow: 0px 4px 10px 2px rgba(146, 143, 198, 0.5);
  height: 4rem;
  min-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchbar input {
  border: 0px;
  height: 60%;
  padding: 0px;
  width: 65%;
  box-sizing: border-box;
  font-family: Marvel;
  padding-left: 10px;
  font-size: 1.2rem;
  border-top-left-radius: 15px; /* Round the top-left corner */
  border-bottom-left-radius: 15px; /* Round the bottom-left corner */
}
.searchbar input:focus {
  border: 1px solid #928fc68c;
  outline: none;
}
.searchbar input::placeholder {
  font-family: Marvel;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.342);
}
.searchbar input::selection {
  background-color: rgba(200, 182, 214, 0.568);
  color: #000;
}
.searchbar button {
  border: 0px;
  height: 38.5px;
  background: #c8b6d6;
  color: #fff;
  font-family: Marvel;
  width: 5rem;
  font-size: 1.4rem;
  transition: background 0.2s ease-in-out;
}
.searchbar button:hover {
  background: #b09bc0;
  transition: background 0.2s ease-in-out;
}
.footer {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 13rem;
  background-color: #1f1e33;
}
.footer .socialmedia {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  padding-bottom: 30px;
}
.footer img {
  padding: 5px;
}
.footer #youtube {
  padding-right: 25px;
}
.footer #github {
  padding-right: 10px;
}
.footer p {
  color: #fff;
  font-family: Marvel;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  margin: 0;
}

.tagbar {
  background: rgb(255, 255, 255);
  box-shadow: 0px 4px 10px 2px rgba(146, 143, 198, 0.5);
  min-width: 320px;
  display: flex;
  flex-direction: column;
}
.tagbar .header {
  font-family: Marvel;
  font-size: 1.7rem;
  font-style: normal;
  /* font-weight: 700; */
  line-height: normal;
  color: #928fc6;
  padding: 25px;
  padding-top: 18px;
  position: relative;
}

.tagbar .header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 21px;
  width: 54px;
  height: 3px;
  background-color: #928fc6;
  border-radius: 10px;
  transform: translateX(23px);
}
.tagbar .body {
  padding: 22px;
  padding-top: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 15px;
}
.tagbar-tag {
  border: 1px solid rgba(146, 143, 198, 0.5);
  background: #fff;
  font-family: Marvel;
  color: #77759c;
  width: 8.2rem;
  height: 2.25rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: background 0.3s ease-in-out;
}
.tagbar-tag:hover {
  background: #b4b1e841;
  transition: background 0.3s ease-in-out;
}
/* .tagbar-tag p{
  padding: 10px;
  margin: none;
} */
.tagbar-tag #num {
  font-family: "Klee One", cursive;
}
.pagination {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.pagination-button {
  border: none;
  background: none;
  font-size: 1.5rem;
  font-family: Marvel;
  padding: 15px;
  color: #471a92;
  transition: background 0.3s ease-in-out;
}
.pagination-button#arrow {
  font-family: "Libre Baskerville", serif;
  font-size: 1.3rem;
}
.pagination-button:hover {
  background: #b4b1e841;
  transition: background 0.3s ease-in-out;
}
.pagination-button.active {
  background-color: #9b97de41;
}


/* Modal  */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 200px;
  transform: translate(-50%, -50%);
  background-color: #2c2b49;
  color: white;
  z-index: 10;
}
.modal button{
  color: white;
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: flex-end;
  align-items: baseline;
  padding: 20px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom: 0;
  background-color: #1f1e338a;
  z-index: 9;
}

/* SearchResultPage */
.searchresult .searchval{
  font-size: 20px;
  font-family: Marvel;
  font-weight: 900;
  color: #412d61;
  padding-bottom: 40px;
}
.searchresult .noresult{
  font-size: 30px;
  font-family: Marvel;
  padding-bottom: 30vh;
}

/* About_Me */
.aboutme{
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.a-aboutme{
  padding: 40px;
  display: flex;
  flex-direction: column;
  height:4000px;
}
.aboutmedate{
  display: flex;
  justify-content: center;
  font-family: Marvel;
  align-items: center;
  font-size: 18px;
  color: #717175a0;

}
.aboutme .last-updated{
  display: flex;
  justify-content:flex-end;
  align-self: flex-end;
  margin-top: auto;
  font-size: 18px;
}
.aboutme .body{
  height: 90%;
}
#aboutme-cover {
  inherits: none;
  font-family: Marvel;
  letter-spacing: 0px;
  padding: 0px;
  font-size: 19px;
  color: #1f1e33;
  background-color: #1f1e33;
}
/* #aboutme-cover :hover{
  background-color: #00000000;
  transition: background-color 0.5s ease-in-out;
} */

.react-pdf__Page__canvas{
  display: none;
}
.react-pdf__Document{
  width: auto;
}
.react-pdf__Page{
  width: 595px;
}
.aboutme .text{
  padding: 8px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;

}
.aboutme #title{
  width: 10px;
  margin-right: 10px;
  min-width: 30px;
  display: block;
  color: #8a7e9ca1;
  font-size: 22px;
  transition: color 0.5s ease-in-out;
}
.aboutme #title:hover{
  color: #1f1e33;
  transition: color 0.5s ease-in-out;
}